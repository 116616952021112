import { template, mapValues } from 'lodash';
import TranslationService from 'product_modules/services/TranslationService';

export enum MessageType {
  FirstNameRequired,
  LastNameRequired,
  CompanyNameRequired,
  EmailRequired,
  EmailInvalid,
  PhoneNumberRequired,
  PhoneNumberInvalid,
  PasswordRequired,
  PasswordRequirementsNotMet,
  PasswordResetEmailSent,
  PasswordRequirementLengthNotMet,
  PasswordRequirementContainNumberNotMet,
  PasswordRequirementContainLowercaseNotMet,
  PasswordRequirementContainUppercaseNotMet,
  PasswordRequirementContainSpecialCharacterNotMet,
  IdentificationNumberInvalid,
  MaximumValue,
  MinimumValue,
}

const getMessages = () => ({
  [MessageType.FirstNameRequired]: TranslationService.translate('validationErrors.firstNameRequired'),
  [MessageType.LastNameRequired]: TranslationService.translate('validationErrors.lastNameRequired'),
  [MessageType.CompanyNameRequired]: TranslationService.translate('validationErrors.companyNameRequired'),
  [MessageType.EmailRequired]: TranslationService.translate('validationErrors.emailRequired'),
  [MessageType.EmailInvalid]: TranslationService.translate('validationErrors.invalidEmail'),
  [MessageType.PhoneNumberRequired]: TranslationService.translate('validationErrors.phoneNumberRequired'),
  [MessageType.PhoneNumberInvalid]: TranslationService.translate('validationErrors.invalidPhoneNumber'),
  [MessageType.PasswordRequired]: TranslationService.translate('validationErrors.passwordRequired'),
  [MessageType.PasswordRequirementsNotMet]: TranslationService.translate('validationErrors.passwordRequirements'),
  [MessageType.PasswordRequirementLengthNotMet]: TranslationService.translate(
    'validationErrors.passwordRequirementsLength',
  ),
  [MessageType.PasswordRequirementContainNumberNotMet]: TranslationService.translate(
    'validationErrors.passwordRequirementsNumber',
  ),
  [MessageType.PasswordRequirementContainUppercaseNotMet]: TranslationService.translate(
    'validationErrors.passwordRequirementsUpperCase',
  ),
  [MessageType.PasswordRequirementContainLowercaseNotMet]: TranslationService.translate(
    'validationErrors.passwordRequirementsLowerCase',
  ),
  [MessageType.PasswordRequirementContainSpecialCharacterNotMet]: TranslationService.translate(
    'validationErrors.passwordRequirementsSpecialCharacter',
  ),
  [MessageType.PasswordResetEmailSent]: TranslationService.translate('toastMessages.resetEmailSent'),
  [MessageType.IdentificationNumberInvalid]: TranslationService.translate(
    'validationErrors.invalidIdentificationNumber',
  ),
  [MessageType.MaximumValue]: TranslationService.translate('validationErrors.invalidMaxNumber'),
  [MessageType.MinimumValue]: TranslationService.translate('validationErrors.invalidMinNumber'),
});

const getMessageTemplates = () => mapValues(getMessages(), (templateString) => template(templateString));

const getMessage = (messageType: MessageType, data?: Record<string, string>) => {
  const messageTemplates = getMessageTemplates();

  return messageTemplates[messageType](data);
};

export default getMessage;
