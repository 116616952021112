import isEmail from 'validator/lib/isEmail';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { MessageType } from 'constants/Messages';

const MIN_PASSWORD_LENGTH = 8;

export const validateEmail = (email: string): MessageType | null => {
  if (!email.length) {
    return MessageType.EmailRequired;
  }

  if (!isEmail(email)) {
    return MessageType.EmailInvalid;
  }

  return null;
};

export const validatePasswordByRules = (password: string): MessageType[] => {
  const passwordValidations = [];

  if (password.length < MIN_PASSWORD_LENGTH) {
    passwordValidations.push(MessageType.PasswordRequirementLengthNotMet);
  }

  if (!/[a-z]/.test(password)) {
    passwordValidations.push(MessageType.PasswordRequirementContainLowercaseNotMet);
  }

  if (!/[A-Z]/.test(password)) {
    passwordValidations.push(MessageType.PasswordRequirementContainUppercaseNotMet);
  }
  
  if (!/[0-9]/.test(password)) {
    passwordValidations.push(MessageType.PasswordRequirementContainNumberNotMet);
  }

  if (!/\W_/.test(password)) {
    passwordValidations.push(MessageType.PasswordRequirementContainSpecialCharacterNotMet);
  }

  return passwordValidations;
};

export const validatePassword = (password: string): MessageType | null => {
  if (!password.length) {
    return MessageType.PasswordRequired;
  }

  if (validatePasswordByRules(password).length) {
    return MessageType.PasswordRequirementsNotMet;
  }

  return null;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber.length) {
    return MessageType.PhoneNumberRequired;
  }

  return isValidPhoneNumber(phoneNumber) ? null : MessageType.PhoneNumberInvalid;
};
